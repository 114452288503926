import { httpService } from 'core-utilities';
import { urlConstants, expiryTimeout } from '../constants/affiliateLinksConstants';

export const qualifiedSignup = async (params: {
  referralUrl: string;
  linkId: string;
}): Promise<void> => {
  const urlConfig = {
    withCredentials: true,
    url: urlConstants.qualifiedSignup,
    timeout: expiryTimeout
  };
  try {
    await httpService.post(urlConfig, params);
  } catch (e) {
    // NOTE (jcountryman, 08/12/2024): Silently fail and not block the signup flow
  }
};

export default {
  qualifiedSignup
};
