import { allowedLinkTypes, allowedPathnames } from '../constants/affiliateLinksConstants';

export const getIsExperienceAffiliatePage = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return (
      allowedPathnames.includes(parsedUrl.pathname) &&
      allowedLinkTypes.includes(parsedUrl.searchParams.get('type') ?? '')
    );
  } catch (e) {
    return false;
  }
};

export const getLinkCode = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    if (allowedPathnames.includes(parsedUrl.pathname)) {
      return parsedUrl.searchParams.get('code') ?? '';
    }
    return '';
  } catch (e) {
    return '';
  }
};
